<template>
  <span class="price-display">
    {{ displayPrice(convertedPrice, float) }}
  </span>
</template>

<script>
export default {
  name: 'PriceDisplay',

  props: {
    price: {
      type: Number,
      required: true,
    },
    showCode: {
      type: Boolean,
      default: false,
    },
    float: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    currentCurrency() {
      return window._data && window._data.currency ? window._data.currency : 'USD'
    },

    convertedPrice() {
      if (!this.currentCurrency || !window.Currency || !window.Currency.rates) return this.price
      return this.price / (window.Currency.rates[this.currentCurrency] || 1)
    },

    displayPrice() {
      return (price, float = true) => {
        const formatter = new Intl.NumberFormat('en', {
          style: 'currency',
          currency: this.currentCurrency,
          currencyDisplay: 'symbol',
          minimumFractionDigits: float ? 2 : 0,
          maximumFractionDigits: float ? 2 : 0,
        })
        const formatted = formatter.format(price).replace('.00', '')
        return formatted
      }
    },
  },
}
</script>
